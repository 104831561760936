import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/common/Layout'

const Member = ({ data, pageContext }) => {
  const books = data.allMarkdownRemark.edges
  return (
    <Layout>
      <section className="w-full flex justify-center">
        <div className="max-w-5xl xl:mr-48">
          <h1 className="text-4xl text-red-rooster text-center mt-4 border-b border-b-red-rooster pb-2 mx-4">{pageContext.bookChooser}</h1>
          <div className="flex justify-center flex-wrap mt-4 p-4">
            {books.map(book => {
              const { title, thumbnail } = book.node.frontmatter
              const { slug } = book.node.fields
              return (
                <Link key={slug} to={slug}>
                  <img className="m-4 shadow-md" key={title} src={thumbnail} />
                </Link>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
query($bookChooser: String!) {
  allMarkdownRemark(
    filter: {frontmatter:{bookChooser:{eq: $bookChooser }}},
    sort: {order: ASC, fields: frontmatter___discussionDate}
  ) {
    edges {
      node {
        frontmatter {
          title
          thumbnail
        }
        fields {
          slug
        }
      }
    }
  }
}
`
export default Member

